@font-face {
    font-family: 'CircularStd';
    font-style: normal;
    font-weight: 400;
    src: local('Circular-Regular'), 
    local('CircularStd-Regular') url('/src/assets/fonts/CircularStd-Book.otf') format('opentype');
}

@font-face {
    font-family: 'CircularStd';
    font-style: normal;
    font-weight: 500;
    src: local('Circular-Medium'), 
    local('CircularStd-Medium') url('/src/assets/fonts/CircularStd-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'CircularStd';
    font-style: normal;
    font-weight: 700;
    src: local('Circular-Bold'), 
    local('CircularStd-Bold') url('/src/assets/fonts/CircularStd-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'CircularStd';
    font-style: normal;
    font-weight: 900;
    src: local('Circular-Black'), 
    local('CircularStd-Black') url('/src/assets/fonts/CircularStd-Black.otf') format('opentype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .page-container{
        @apply w-[90%] sm:w-full mx-auto container;
    }

    .btn{
        @apply rounded-full font-bold py-2 px-4 text-center;
    }

    .btn.transparent{
        @apply border-2 border-aoki-blue-light text-aoki-blue-light;
    }

    .btn.dark{
        @apply bg-aoki-blue text-white;
    }

    .form-label{
        @apply mb-2 inline-block font-bold text-gray-500;
    }

    .app-status{
        @apply page-container font-bold text-aoki-gray text-lg tracking-wide;
    }

    .link{
        @apply py-2 px-6 text-white text-center font-bold md:text-sm rounded-sm;
    }

    .link.normal{
        @apply bg-aoki-orange hover:bg-aoki-orange/75;
    }

    .link.ghost{
        @apply bg-aoki-purple;
    }

    .link.app{
        @apply 
            capitalize 
            bg-aoki-blue 
            flex 
            items-center 
            justify-center 
            py-[.4em]
            px-2
            min-h-[2.5rem]
            shadow-md
            shadow-[#0005]
            leading-tight
            rounded-md;
    }

    .link.app.tooltip{
        @apply
            relative
            after:transition-opacity
            after:duration-500
            after:content-[attr(data-text)]
            after:block
            after:p-2
            after:w-full
            after:rounded-md
            after:text-xs
            after:bg-aoki-gray/70
            after:absolute
            after:top-[110%]
            after:left-0
            after:opacity-0
            after:z-50
            after:pointer-events-none
            pointer-fine:hover:after:opacity-100
            pointer-fine:hover:after:pointer-events-auto;
    }

    .app-links-container{
        @apply 
            grid 
            grid-cols-[repeat(auto-fill,minmax(var(--link-size),1fr))] 
            auto-rows-fr
            gap-2
            mb-3;
    }

    .article-links{
        @apply [&:not(:last-of-type)]:mb-6;
    }
}

@media (pointer: fine) {
    .odd-option-link{
        @apply hover:bg-gray-800 hover:border-gray-800;
    }
    .odd-link{
        @apply hover:bg-sky-800 hover:border-sky-800;
    }
}

:root{
    --header-size: 4rem;
    --link-size: 150px;
}

@media screen and (min-width: 768px) {
    :root{
        --link-size: 200px;
    }
}

body > #root{
    display: grid;
    grid-template-rows: min-content auto min-content;
    min-height: 100vh;
}

body.home > #root{
    grid-template-rows: auto min-content;
    min-height: calc(100vh - var(--header-size));
    margin-top: var(--header-size);
}

body.home > #root main{
    height: calc(100vh - var(--header-size));
    overflow: auto;
}

html{
    font-size: 0.8125rem;
}

@media screen and (min-width: 20rem){
    html{
        font-size: calc(0.8125rem + 0.1875 * ((100vw - 20rem) / 61.25));
    }
}

@media screen and (min-width: 81.25rem){
    html{
        font-size: 1rem;
    }
}

.password-border{
    @apply 
        after:absolute 
        after:content-[''] 
        after:block 
        after:bottom-0 
        after:left-0 
        after:w-full 
        after:h-[2px] 
        after:bg-gray-300 
        focus-within:after:bg-aoki-orange
        after:transition-[background-color];
}

main{
    @apply bg-aoki-gray-light;
}

.button-link{
    @apply 
        rounded
        min-h-[35px] 
        py-[1px]
        px-[5px]
        text-sm 
        md:text-xs 
        font-semibold 
        border 
        border-solid 
        odd-link 
        uppercase 
        w-full 
        transition-colors 
        md:h-full 
        text-center 
        flex 
        items-center 
        justify-center;
}

body.login main {
    @apply bg-aoki-blue-light;
}
